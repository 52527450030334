import React from 'react'
import { graphql } from 'gatsby'
import PageLayout from '../layouts/PageLayout/PageLayout'
import styled from 'styled-components'
import LinkOrButton from '../components/LinkOrButton'
import { useMenuItems } from '../hooks/useMenuItems'

const BrandPageTemplate = ({ data }) => {
	const brands = data.allBrand.nodes
	const headerData = data.wpPage.header
	const menuItems = useMenuItems()

	const pageData = {
		seo: {
			canonical: `/sitemap/`,
			cornerstone: false,
			focuskw: `Sitemap`,
			metaDesc: `ECU Adaptions sitemap`,
			metaKeywords: `Sitemap`,
			metaRobotsNofollow: 'follow',
			metaRobotsNoindex: 'index',
			opengraphTitle: `ECU Adaptions - sitemap`,
			title: `ECU Adaptions - sitemap`
		},
		header: {
			...headerData,
			heroText: 'Sitemap'
		}
	}

	return (
		<PageLayout pageData={pageData}>
			<BrandPageContentWrapper>
				<h1>{`Hoofdpagina's`}</h1>
				<LinkList>
					{menuItems.map(menuItem => (
						<LinkContainer key={menuItem.id}>
							<LinkOrButton type={'link'} url={menuItem.uri} text={menuItem.label} />
						</LinkContainer>
					))}
				</LinkList>
				<h1>Chiptunen auto informatie</h1>
				<LinkList>
					{brands.map(brand => (
						<LinkContainer key={brand.slug}>
							<LinkOrButton type={'link'} url={`/chiptunen/${brand.slug}/`} text={`Chiptunen ${brand.name}`} />
						</LinkContainer>
					))}
				</LinkList>
				<h1>Autosleutels</h1>
				<LinkList>
					{brands.map(brand => (
						<LinkContainer key={brand.slug}>
							<LinkOrButton type={'link'} url={`/autosleutels/${brand.slug}/`} text={`Autosleutels ${brand.name}`} />
						</LinkContainer>
					))}
				</LinkList>
			</BrandPageContentWrapper>
		</PageLayout>
	)
}

const BrandPageContentWrapper = styled.section`
	padding: 80px 0;
`

const LinkList = styled.div`
	display: flex;
	flex-direction: column;
`

const LinkContainer = styled.div`
	margin-top: 0.3rem;
	margin-bottom: 0.3rem;
`

export default BrandPageTemplate

export const query = graphql`
	query {
		wpPage(databaseId: { eq: 187 }) {
			header {
				heroChoice
				image {
					sourceUrl
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1920)
						}
					}
				}
				videoUrl
				height
				backgroundOpacity
				backgroundColor
				pxOrVh
			}
		}
		allBrand {
			nodes {
				slug
				name
			}
		}
	}
`
